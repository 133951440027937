import React from "react";
import {HeaderLoginBox, HeaderLoginIcon} from "../../Style/Header";
import {NavLink} from "react-router-dom";

export const HeaderLogin = () => {

  return(
    <HeaderLoginBox>
      <HeaderLoginIcon/>
      <NavLink to={'/login'}>
        <p>Войти</p>
      </NavLink>
    </HeaderLoginBox>
  )
}