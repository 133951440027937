import React, {useEffect} from "react";
import {CompetitorContainer} from "../Style/MemberPage";
import {
  BreadcrumbsItem,
  BreadcrumbsItemLink, DocsContent,
  DocsTypesTitle, DocsTypesTitleBg,
  DocumentsPageContent
} from "../Style/DocumentsPage/DocumentsPage";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {PageTitle} from "../Style/Pages";
import {SetDocs} from "../components/Documents/SetDocs";

import competitorMain from '../assets/file/CompetitorMain.docx'
import competitorAdd from '../assets/file/CompetitiorAdd.docx'
import competitorData from '../assets/file/CompetitorData.docx'
import gide from '../assets/file/gide.pdf'

export const CompetitorPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return(
    <CompetitorContainer>
      <DocumentsPageContent>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs separator={' / '}>
                <Breadcrumb data={{
                  title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                  pathname: '/'
                }}/>
                <Breadcrumb data={{
                  title: <BreadcrumbsItemLink>Участникам</BreadcrumbsItemLink>,
                  pathname: '/docs'
                }}/>
              </Breadcrumbs>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <PageTitle>Участникам</PageTitle>
            </Col>
          </Row>

          <Col lg={12}>
            <DocsTypesTitle>
              <DocsTypesTitleBg/>
              <h2>ДОКУМЕНТЫ</h2>
            </DocsTypesTitle>
          </Col>
          <Col lg={10} md={12}>
            <DocsContent>
              <SetDocs
                file={gide}
                type={'PDF'}
                size={'587 Кб'}
                title={'Инструкция для участников Всероссийского дистанционного конкурса среди классных руководителей на лучшие методические разработки воспитательных мероприятий по работе в личном кабинете на официальном сайте Конкурса'}
              />
              <SetDocs
                file={competitorMain}
                type={'DOC'}
                size={'13 Кб'}
                title={'Заявка на участие во Всероссийском дистанционном конкурсе среди классных руководителей на лучшие методические разработки воспитательных мероприятий'}
              />
              <SetDocs
                file={competitorAdd}
                type={'DOC'}
                size={'14 Кб'}
                title={'Требования к структуре и содержанию методической разработки воспитательного мероприятия'}
              />
              <SetDocs
                file={competitorData}
                type={'DOC'}
                size={'15 Кб'}
                title={'Согласие участника Всероссийского дистанционного конкурса среди классных руководителей на лучшие методические разработки воспитательных мероприятий на обработку персональных данных'}
              />
            </DocsContent>
          </Col>


        </Grid>
      </DocumentsPageContent>
    </CompetitorContainer>
  )
}