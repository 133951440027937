import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import {useRoutes} from "./Routes/routes";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {
  authorization,
  changeLogin,
  changePassword,
  changeRemember,
  setRole,
  setToken
} from "./Redux/actions/authActions";
import {useHttp} from "./hooks/http.hooks";

function App() {
  const isAuthenticated = useSelector(state => state.auth.isAuth)
  const routes = useRoutes(isAuthenticated)
  const dispatch = useDispatch()
  const {request} = useHttp()

  useEffect( () => {
    const fetchUser = async id => {
      try {
        const user = await request('/api/auth/getUserRole', 'POST', {
          id
        })
        dispatch(setRole(user.role))
      } catch (e) {
        dispatch(authorization(false))
        dispatch(setToken(null))
      }
    }
    const data = JSON.parse(localStorage.getItem('userLogin'))
    const user = JSON.parse(localStorage.getItem('userData'))
    if (data) {
      dispatch(changeLogin(data.login))
      dispatch(changePassword(data.password))
      dispatch(changeRemember(true))
    }
    if (user) {
      fetchUser(user.userId)
    }
  }, [dispatch, request])

  return (
    <Router>
      {routes}
    </Router>
  );
}

export default App;
