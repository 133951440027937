import logo from '../assets/svg/logoBig.svg'
import nope from '../assets/img/news/fff.png'


export const news = [
  {
    id: new Date().toString() + 9,
    date: {
      day: '25',
      mon: 'декабря',
      year: '2020',
      time: 'г. 14:26'
    },
    data: {
      title: `Уважаемые участники конкурса! Электронный сертификат участника может
получить каждый конкурсант, который разместил методическую разработку
в личном кабинете.`,
      description: `ПОРЯДОК ПОЛУЧЕНИЯ ЭЛЕКТРОННЫХ СЕРТИФИКАТОВ УЧАСТНИКА КОНКУРСА
Уважаемые участники конкурса! Электронный сертификат участника может
получить каждый конкурсант, который разместил методическую разработку
в личном кабинете. Для конкурсантов, зарегистрировавшихся, но не
разместивших работу в личном кабинете, выдача сертификата не
предусмотрена.
Для получения сертификата нужно воспользоваться формой входа в личный
кабинет (кнопка «Войти» на главной странице сайта). В открывшейся форме
входа необходимо нажать кнопку «Получить сертификат». Далее нужно
ввести фамилию (так, как Вы указывали ее при регистрации в личном
кабинете, с учетом заглавных букв, пробелов и т.д.), выбрать из
выпадающего списка субъект РФ и тематическое направление Вашей работы.
Если все данные введены верно, при нажатии на кнопку «Получить» Ваш
электронный сертификат будет доступен для сохранения.
В случае ошибки при вводе данных появится окно «Сертификат недоступен».
В этом случае необходимо проверить правильность введенных данных.
 `
    },
    img: nope
  },
  {
    id: new Date().toString() + 8,
    date: {
      day: '30',
      mon: 'ноября',
      year: '2020',
      time: 'г. 10:05'
    },
    data: {
      title: `Уважаемые участники! С 30 ноября начинается подведение итогов конкурса.`,
      description: `Уважаемые участники! С 30 ноября начинается подведение итогов конкурса.

Победитель, призеры и лауреаты конкурса определяются по итогам оценки работ членами жюри на основании рейтингового списка. Конкурсант, занимающий первую позицию рейтингового списка, объявляется победителем конкурса. Два конкурсанта, занимающие вторую и третью позиции, объявляются призерами. Восемь конкурсантов, занимающих следующие после победителя и призеров позиции, объявляются лауреатами.

Рейтинговый список, включающий победителя, призеров и лауреатов конкурса, утверждается Министерством просвещения Российской Федерации.

Победитель, призеры и лауреаты конкурса награждаются соответствующими дипломами. Остальные участники, предоставившие конкурсные материалы, получат сертификаты участника конкурса в электронном виде. О порядке получения сертификатов будет объявлено дополнительно.

Итоги конкурса будут опубликованы на официальном сайте конкурса после утверждения Министерством просвещения Российской Федерации. Общий рейтинговый список, согласно Положению о конкурсе, не публикуется.


 `
    },
    img: nope
  },
  {
    id: new Date().toString() + 7,
    date: {
      day: '7',
      mon: 'ноября',
      year: '2020',
      time: 'г. 13:32'
    },
    data: {
      title: `Уважаемые участники Конкурса! Этап размещения конкурсных работ завершен. 7 ноября 2020 г. начался этап экспертизы материалов членами жюри Конкурса.`,
      description: `Уважаемые участники Конкурса! Этап размещения конкурсных работ завершен. 7 ноября 2020 г. начался этап экспертизы материалов членами жюри Конкурса.
                    В составе жюри – руководители и педагоги организаций дополнительного профессионального образования, высшего образования, общего образования из более чем 30 субъектов Российской Федерации. Среди экспертов Конкурса - доктора и кандидаты наук, аспиранты и магистранты направлений педагогической подготовки, победители и лауреаты конкурсов профессионального мастерства, обладатели почетных званий и наград в сфере науки и образования.
                    На период экспертизы операции в личных кабинетах для участников Конкурса будут недоступны. Подведение итогов Конкурса и объявление результатов состоится 10 декабря.
                    Желаем вам удачи!`
    },
    img: nope
  },
  {
    id: new Date().toString() + 6,
    date: {
      day: '5',
      mon: 'ноября',
      year: '2020',
      time: 'г. 12:12'
    },
    data: {
      title: 'Всероссийский конкурс среди классных руководителей проводится при участии организаций-партнеров.',
      description: `Всероссийский конкурс среди классных руководителей проводится при
                    участии организаций-партнеров. Партнеры Конкурса - Общероссийская
                    общественно-государственная детско-юношеская организация «Российское
                    движение школьников»; публично-правовая компания «Российский
                    экологический оператор»; Общероссийская организация «Городские
                    реновации».
                    Представители организаций-партнеров включены в состав жюри
                    Конкурса. Эксперты ППК «Российский экологический оператор» и ОО
                    «Городские реновации» примут участие в оценивании работ по таким
                    популярным среди участников Конкурса направлениям, как «Трудовое
                    воспитание и профессиональное самоопределение», «Гражданское
                    воспитание», «Патриотическое воспитание и формирование российской
                    идентичности».
                    Представители Российского движения школьников также примут
                    участие в экспертизе конкурсных работ по нескольким тематическим
                    направлениям и наградят победителей I, II и III призовых мест памятными
                    подарками. Кроме того, лучшие методические разработки, отобранные по
                    итогам Конкурса, будут размещены сайте Корпоративного университета
                    РДШ.`
    },
    img: nope
  },
  {
    id: new Date().toString() + 5,
    date: {
      day: '29',
      mon: 'октября',
      year: '2020',
      time: 'г. 14:38'
    },
    data: {
      title: 'С 1 по 15 октября 2020 г. поступило более 10 000 заявок на участие. Министерство просвещения Российской Федерации продлевает сроки предоставления конкурсных материалов до 6 ноября.',
      description: `С 1 по 15 октября 2020 г. поступило более 10 000 заявок на участие в
                    конкурсе от классных руководителей, что свидетельствует о большом
                    интересе к конкурсу в педагогическом сообществе.
                    Министерство просвещения Российской Федерации продлевает сроки
                    предоставления конкурсных материалов до 6 ноября.
                    Логины и пароли для входа в личный кабинет будут предоставлены
                    тем, кто прислал заявку на участие, в ближайшее время.
                    Напоминаем, что предоставление материалов производится только
                    через личный кабинет участника.`
    },
    img: nope
  },
  {
    id: new Date().toString() + 4,
    date: {
      day: '16',
      mon: 'октября',
      year: '2020',
      time: 'г. 11:34'
    },
    data: {
      title: 'Завершился прием заявок на участие во Всероссийском дистанционном конкурсе среди классных руководителей на лучшие методические разработки воспитательных мероприятий.',
      description: `Завершился прием заявок на участие во Всероссийском дистанционном конкурсе среди классных руководителей на лучшие методические разработки воспитательных мероприятий.

Поступило несколько тысяч заявок от педагогов начального, среднего, основного общего образования, выполняющих обязанности по классному руководству, из более чем 80 субъектов Российской Федерации. 

Наиболее популярными тематическими направлениями среди предложенных на конкурс работ остаются патриотическое воспитание, духовно-нравственное воспитание, приобщение к культурному наследию. Также многие материалы посвящены практикоориентированной работе с детьми в рамках экологического воспитания, что говорит о растущем интересе учителей к этой тематике.

Теперь зарегистрированным участникам до 31 октября предстоит разместить свои конкурсные материалы в личных кабинетах на официальном сайте конкурса. Этап оценивания работ будет проходить с 1 по 20 ноября. Итоги конкурса будут объявлены 10 декабря.`
    },
    img: nope
  },
  {
    id: new Date().toString() + 3,
    date: {
      day: '12',
      mon: 'октября',
      year: '2020',
      time: 'г. 10:50'
    },
    data: {
      title: '15 октября 2020 г. завершается прием заявок на участие в Конкурсе. На данный момент поступило более 700 заявок от классных руководителей из более чем 50 субъектов Российской Федерации.',
      description: `15 октября 2020 г. завершается прием заявок на участие в Конкурсе. На данный момент поступило более 700 заявок от классных руководителей из более чем 50 субъектов Российской Федерации. В числе субъектов – Оренбургская, Псковская, Ярославская, Тамбовская, Курская, Московская область; Республика Адыгея, Республика Татарстан, Республика Башкортостан; Алтайский, Ставропольский край; г. Санкт-Петербург. Среди самых популярных тематических направлений конкурсных работ - патриотическое воспитание и формирование российской идентичности; духовное и нравственное воспитание; популяризация научных знаний; приобщение к культурному наследию.`
    },
    img: nope
  },
  {
    id: new Date().toString() + 2,
    date: {
      day: '1',
      mon: 'октября',
      year: '2020',
      time: 'г. 10:04'
    },
    data: {
      title: 'Открыт прием заявок на участие в Конкурсе. Заявки на участие высылаются классными руководителями оператору Конкурса на электронный адрес dzeboeva@apkpro.ru в срок не позднее 15 октября 2020 года.',
      description: `Открыт прием заявок на участие в Конкурсе. Заявки на участие высылаются классными руководителями оператору Конкурса на электронный адрес dzeboeva@apkpro.ru в срок не позднее 15 октября 2020 года. Форма заявки размещена в Положении о конкурсе, а также разделе «Участникам». Подробная информация о порядке, сроках проведения и условиях участия содержится в Положении о конкурсе. По вопросам участия можно обращаться по телефону: +7 (495) 969-26-17 (доб. 1246), Дзебоева Зарина Юрьевна.`
    },
    img: nope
  },
  {
    id: new Date().toString() + 1,
    date: {
      day: '23',
      mon: 'сентября',
      year: '2020',
      time: 'г. 13:25'
    },
    data: {
      title: '1 октября 2020 года начинается этап сбора заявок от участников Конкурса и предоставления конкурсных материалов.',
      description: `1 октября 2020 года начинается этап сбора заявок от участников Конкурса и предоставления конкурсных материалов. Заявки на участие высылаются Оператору Конкурса на электронный адрес dzeboeva@apkpro.ru в срок не позднее 15 октября 2020 года. Подробная информация о порядке и условиях конкурсного отбора содержится в Положении о конкурсе.`
    },
    img: logo
  },
]