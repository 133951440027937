import styled from "styled-components";

import bigLogo from '../assets/svg/logoBig.svg'
import adminIcon from '../assets/svg/IconAdmin.svg'
import regionRole from '../assets/svg/RegionRole.svg'
import headerBg1200 from '../assets/img/header/header1200.jpg'
import headerBg1900 from '../assets/img/header/header1980.jpg'
import headerBg780 from '../assets/img/header/header780.jpg'
import roleBack from '../assets/svg/roleBack.png'
import adminRole from '../assets/svg/adminRole.svg'
import userRole from '../assets/svg/userRole.svg'


export const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  height: 100px;
  background-size: cover;
`

export const LogoBox = styled.div`
  background-image: url(${bigLogo});
  position: absolute;
  top: 63px;
  left: 73px;
  height: 120px;
  width: 143px;
`

export const HeaderLoginBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 78px;
  color: #f6a117;
  
  @media (max-width: 800px) {
  display: none;
  }
  
  & p {
    color: #f6a117;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 30px;
    transition: color .3s;
    cursor: pointer;
    
    &:hover {
      color: #1795b1;
    }
  }
`

export const HeaderLoginIcon = styled.div`
 background-image: url(${adminIcon});   
 margin-right: 11px;
 width: 20px;
 height: 21px;
 background-size: cover;
 
`

export const HeaderMenuBox = styled.div` 
  min-width: 400px;
  margin-left: 35%;
  
  @media (max-width: 800px) {
  display: none;
  }
`

export const PageContent = styled.div`  
  max-width: 1280px;
  margin: 0 auto;
`

export const HeaderBackground = styled.div`
  width: 100%;
  height: 680px;
  background-size: cover;
   
  
  @media (max-width: 1399.5px) {
    background-image: url(${headerBg1200});
  }
  
  
  @media (min-width: 1400px) {
    background-image: url(${headerBg1900});
  }
  @media (max-width: 900.5px) {
    background-image: url(${headerBg780});
  }
`

export const HeaderMenuList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const HeaderMenuLink = styled.div`
  cursor: pointer;
  font-size: 18px;
  color: #052942;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
  transition: color .3s;
  
  &:hover {
  color: #1795b1;
  }
  
`

export const HeaderInfo = styled.div`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 450px;
`

export const HeaderTitleText = styled.p`
  margin-left: 71px;
  color: #ffffff;
  font-size: 30px;
  max-width: 600px;
  font-weight: 700;
  line-height: 54.32px;
  text-transform: uppercase;
  
`

export const RoleButtonsBox = styled.div`
  display: flex;
  margin: 0 71px;
  justify-content: center;  
  
  @media (max-width: 800px)  {
    display: none;
  } 
`

export const RoleButtonBox = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 100px;
  
`
export const RoleButtonArea = styled.div`
  width: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 105px;
  background-size: cover;
  background-image: url(${roleBack});
`
export const RoleButtonImage = styled.div`
  width: 85px;
  height: 85px;
  background-size: cover;
  background-image: ${props => {
    if (props.icon === 'moder') {
      return `url(${regionRole})`
    }
    if (props.icon === 'user') {
      return `url(${userRole})`
    }
    if (props.icon === 'admin') {
      return `url(${adminRole})`
    }
}};
`
export const RoleButtonDescription = styled.h2`
  max-width: 167px;
  display: flex;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
`
export const HeaderDropDown = styled.div`
  display: none;
  position: absolute; 
  bottom: -170px;
  width: 300px;
  height: 170px;
  box-shadow: 0 0 24px #5b5b5b;
  background-color: #e2f0f2;
  opacity: 0.92;
  flex-direction: column; 
  justify-content: center;
`

export const HeaderDropDownBox = styled.div`
 position: relative; 
 max-height: 30px;
 
 &:hover {
  & ${HeaderDropDown} {
    display: flex;
  }
 }
`

export const DropDownText = styled.div`
  color: #041f32;
  font-family: "Roboto Condensed", serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 44px;
  padding-left: 30px;
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    color: #1795b1;
  }
`
