import styled from 'styled-components'

import minPros from '../../assets/img/partners/minpros.png'
import fgyp from '../../assets/img/partners/FGAOY.png'
import vestnik from '../../assets/img/partners/Vestnik.png'
import rusPortal from '../../assets/img/partners/RosObr.png'
import rdh from '../../assets/img/partners/rdh.png'
import gr from '../../assets/img/partners/gr.png'
import reo from '../../assets/img/partners/reo.png'

export const PartnersContainer = styled.div`
  width: 100%;
  min-height: 350px;
  margin-top: 30px;
`

export const PartnersContent = styled.div`
  max-width: 1280px;
  margin: 25px auto;
  height: 100%;

  & h1  {
    margin-left: 71px;
    margin-bottom: 56px;
    color: #052c47;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }
`

export const PartnersBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 80px;
  margin-right: 70px;
`
export const PartnersItem = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;

  & p {
    text-align: center;
    max-width: 210px;
    color: #052c47;
    font-family: "Roboto Condensed", serif;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
  }
`
export const PartnersImage = styled.div.attrs(props => {
  if (props.type === 'minPros') {
    return ({
      width: '160px',
      height: '140px',
      image: minPros
    })
  }
  if (props.type === 'fgyp') {
    return ({
      width: '137px',
      height: '124px',
      image: fgyp
    })
  }
  if (props.type === 'vestnik') {
    return ({
      width: '247px',
      height: '88px',
      image: vestnik,
      top: '40px'
    })
  }
  if (props.type === 'rusPortal') {
    return ({
      width: '94px',
      height: '115px',
      image: rusPortal
    })
  }
  if (props.type === 'reo') {
    return ({
      width: '123px',
      height: '124px',
      image: reo
    })
  }
  if (props.type === 'gr') {
    return ({
      width: '138px',
      height: '115px',
      image: gr
    })
  }
  if (props.type === 'rdh') {
    return ({
      width: '114px',
      height: '97px',
      image: rdh
    })
  }
})`
  width: ${props => props.width};
  height: ${props => props.height};
  background-size: cover;
  background-image: url(${props => props.image});
  margin-bottom: 14px;
  margin-top: ${props => props.top};
`