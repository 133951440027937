import React from "react";
import {PartnersBox, PartnersContainer, PartnersContent, PartnersImage, PartnersItem} from "../../Style/HomePage/Partners";


export const Partners = () => {

  return (
    <PartnersContainer>
      <PartnersContent>
        <h1>Учредители и организаторы</h1>
        <PartnersBox>
          <a href="https://edu.gov.ru/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'minPros'}/>
              <p>МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ
                РОССИЙСКОЙ ФЕДЕРАЦИИ</p>
            </PartnersItem>
          </a>
          <a href="https://eit.edu.ru/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'fgyp'}/>
              <p>ФГАОУ ДПО ЦРГОП и ИТ</p>
            </PartnersItem>
          </a>
          <a href="https://vestnik.edu.ru/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'vestnik'}/>
            </PartnersItem>
          </a>
          <a href="http://www.edu.ru/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'rusPortal'}/>
              <p>российский образовательный
                федеральный портал</p>
            </PartnersItem>
          </a>
        </PartnersBox>
      </PartnersContent>
      <PartnersContent>
        <h1>Партнеры</h1>
        <PartnersBox>
          <a href="https://reo.ru/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'reo'}/>
              <p>Российский
                экологический оператор</p>
            </PartnersItem>
          </a>
          <a href="https://urbanrenovation.ru/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'gr'}/>
              <p>Городские
                реновации</p>
            </PartnersItem>
          </a>
          <a href="https://xn--d1axz.xn--p1ai/" target={'_blank'} rel="noopener noreferrer">
            <PartnersItem>
              <PartnersImage type={'rdh'}/>
              <p>Российское
                движение школьников</p>
            </PartnersItem>
          </a>
        </PartnersBox>
      </PartnersContent>
    </PartnersContainer>
  )
}