import React, {useEffect} from "react";
import {Number, TopicContent, TopicItem, TopicPageContainer} from "../Style/TopicPage";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {BreadcrumbsItem, BreadcrumbsItemLink} from "../Style/DocumentsPage/DocumentsPage";
import {DisplayFlex, PageTitle} from "../Style/Pages";
import {topics} from "../Docs/topics";

export const TopicPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <TopicPageContainer>
      <Grid fluid>
        <Row>
          <Col lg={12}>
            <Breadcrumbs separator={' / '}>
              <Breadcrumb data={{
                title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                pathname: '/'
              }}/>
              <Breadcrumb data={{
                title: <BreadcrumbsItemLink>Тематические направления</BreadcrumbsItemLink>,
                pathname: '/topic'
              }}/>
            </Breadcrumbs>
          </Col>
          <Row>
            <Col lg={12}>
              <DisplayFlex>
                <PageTitle>Тематические направления</PageTitle>
              </DisplayFlex>
            </Col>
          </Row>
        </Row>
        <TopicContent>
          {topics.map((node, _) => (
            <Row key={_}>
              <TopicItem>
                <Col sm={1}>
                  <Number>{_ + 1}</Number>
                </Col>

                <Col sm={11}>
                  <p>{node}</p>
                </Col>
              </TopicItem>
            </Row>
          ))}
        </TopicContent>
      </Grid>
    </TopicPageContainer>
  )
}