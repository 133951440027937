import React, {useEffect} from "react";
import {Col, Grid, Row} from "react-flexbox-grid";
import logo from '../assets/svg/logoBig.svg'
import {BreadcrumbsItem, BreadcrumbsItemLink, DocumentsPageContainer} from "../Style/DocumentsPage/DocumentsPage";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {DisplayFlex, PageTitle} from "../Style/Pages";
import {
  AboutPageContainer,
  CustomText,
  FullWidthContainer,
  Image, NewsTaskContainer, NewsText,
  UpperContainer,
  UpperNewsContainer
} from "../Style/AboutPage";



export const AboutPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <AboutPageContainer>
      <DocumentsPageContainer>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs separator={' / '}>
                <Breadcrumb data={{
                  title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                  pathname: '/'
                }}/>
                <Breadcrumb data={{
                  title: <BreadcrumbsItemLink>О конкурсе</BreadcrumbsItemLink>,
                  pathname: '/about'
                }}/>
              </Breadcrumbs>
            </Col>
            <Row>
              <Col lg={12}>
                <DisplayFlex>
                  <PageTitle>О конкурсе</PageTitle>
                </DisplayFlex>
              </Col>
            </Row>
          </Row>
        </Grid>
      </DocumentsPageContainer>
      <DocumentsPageContainer>
      <UpperNewsContainer>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <UpperContainer>
                <Image src={logo} alt="logo" />
                <CustomText>
                  <p>
                    Министерство просвещения Российской Федерации объявляет о
                    проведении Всероссийского дистанционного конкурса среди классных
                    руководителей на лучшие методические разработки воспитательных
                    мероприятий.
                  </p>
                  <br/>
                  <p>
                    Цели Конкурса: выявление лучших методических разработок
                    воспитательных мероприятий, распространение инновационного
                    педагогического опыта классных руководителей.
                  </p>
                  <br/>
                  <p>
                    Сроки проведения Конкурса: 1 октября – 10 декабря 2020 года.
                    Подробная информация о порядке и условиях конкурсного отбора
                    содержится в Положении о конкурсе.
                  </p>
                  <br/>
                  <p>
                    Лучшие из представленных на Конкурс методических разработок
                    будут опубликованы в журнале «Вестник образования».
                  </p>
                  <br/>
                  <h2>Контактное лицо - Дзебоева Зарина Юрьевна Тел.: +7 (495) 969-26-17 (доб. 1246), dzeboeva@apkpro.ru</h2>
                </CustomText>
              </UpperContainer>
            </Col>
          </Row>
        </Grid>
      </UpperNewsContainer>
      </DocumentsPageContainer>
      <FullWidthContainer>
        <NewsTaskContainer>
          <Grid fluid>
            <DocumentsPageContainer>
            <Row>
              <Col lg={12}>
                <NewsText>
                  <div>
                    <p>Задачи конкурса:</p>
                    <ul>
                      <li>
                        создание условий для развития творческого потенциала педагогических работников, выполняющих функции классных руководителей;
                      </li>
                      <li>
                        стимулирование инновационной деятельности классного руководителя;
                      </li>
                      <li>
                        содействие совершенствованию методической компетентности классных руководителей;
                      </li>
                      <li>
                        формирование экспертного сообщества в области современного методического обеспечения воспитательной деятельности в общеобразовательных организациях;
                      </li>
                      <li>
                        повышение качества воспитательной составляющей в деятельности общеобразовательных организаций.
                      </li>
                    </ul>
                  </div>
                </NewsText>
              </Col>
            </Row>
            </DocumentsPageContainer>
          </Grid>
        </NewsTaskContainer>
      </FullWidthContainer>
    </AboutPageContainer>
  )
}