import React from "react";
import {
  CalendarArea,
  CalendarDate, CalendarDescription,
  CalendarLink,
  Hyphen,
  NewsCalendarBox,
  NewsCompetitionsTitle
} from "../../Style/HomePage/News";
import {NavLink} from "react-router-dom";


export const NewsCalendar = () => {

  return(
    <NewsCalendarBox>
      <NewsCompetitionsTitle>КАЛЕНДАРЬ</NewsCompetitionsTitle>
      <CalendarArea>
        <CalendarDate>
          <p>30</p>
          <div>Ноября</div>
        </CalendarDate>
        <Hyphen/>
        <CalendarDate>
          <p>10</p>
          <div>Декабря</div>
        </CalendarDate>
      </CalendarArea>
      <NavLink to={'/calendar'}>
        <CalendarLink>Этапы</CalendarLink>
      </NavLink>
      <CalendarDescription>Подведение итогов Конкурса, утверждение результатов Учредителем Конкурса</CalendarDescription>
      {/*<NavLink to={'/calendar'}>*/}
      {/*  <Button value={'график проведения конкурса'} height={'280px'} type={'primary'}/>*/}
      {/*</NavLink>*/}
      {/*<Button value={'региональные сайты конкурса'} height={'280px'}/>*/}
    </NewsCalendarBox>
  )
}