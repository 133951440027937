import React from "react";
import {Col} from "react-flexbox-grid";
import {
  DocsContent,
  DocsTypesTitle,
  DocsTypesTitleBg,
} from "../../Style/DocumentsPage/DocumentsPage";
import {SetDocs} from "../../components/Documents/SetDocs";
import {InsertTo} from "../../components/Documents/InsertTo";
import docsStrat from "../../assets/file/DocsStrat.pdf";
import docsMain from "../../assets/file/aboutComp.pdf"
// import {WinnerDocs} from "../../components/Documents/WinnersDocs";

export const DocsTypes = () => {


  return (
    <>
      <Col lg={10} md={12}>
        <DocsContent>
          <SetDocs
            file={docsMain}
            type={'PDF'}
            size={'2 938 Кб'}
            title={'Положение о Всероссийском дистанционном конкурсе среди классных руководителей на лучшие методические разработки воспитательных мероприятий'}
          />
          <SetDocs
            file={docsStrat}
            type={'PDF'}
            size={'189 Кб'}
            title={'Стратегия развития воспитания в Российской Федерации на период до 2025 года (утв. распоряжением Правительства Российской Федерации 29 мая 2015 г. №996-р)'}
          />
        </DocsContent>
      </Col>

      <Col lg={12}>
        <DocsTypesTitle>
          <DocsTypesTitleBg/>
          <h2>Символика конкурса</h2>
        </DocsTypesTitle>
      </Col>
      <InsertTo/>

      {/*<Col lg={12}>*/}
      {/*  <DocsTypesTitle>*/}
      {/*    <DocsTypesTitleBg/>*/}
      {/*    <h2>Дипломы конкурса</h2>*/}
      {/*  </DocsTypesTitle>*/}
      {/*</Col>*/}

      {/*<Row lg={6} sm={6}>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*  <Col lg={4} sm={6} md={6}>*/}
      {/*    <WinnerDocs/>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

    </>
  )
}