import React from "react";
import {AhrefField, BanerBox, BanerContainer, InsertToTitle} from "../../Style/DocumentsPage/DocumentsPage";
import {LayoutLogoImage} from "../../Style/LayoutHeader";
export const InsertTo = () => {

  return (
    <>
      <InsertToTitle>
        Скопируйте код баннера и разместите его на Вашем сайте или блоге:
      </InsertToTitle>
      <BanerContainer>
        <BanerBox>
          <div>
            <LayoutLogoImage/>
            <div>
              <p>
                ВСЕРОССИЙСКИЙ КОНКУРС
              </p>
                <p>
                СРЕДИ КЛАССНЫХ РУКОВОДИТЕЛЕЙ НА ЛУЧШИЕ МЕТОДИЧЕСКИЕ РАЗРАБОТКИ ВОСПИТАТЕЛЬНЫХ МЕРОПРИЯТИЙ
              </p>
            </div>
          </div>
        </BanerBox>
        <AhrefField>
          {'<a href="https://vkkr.edu.ru/"><img src="https://vkkr.edu.ru/static/media/vkkr-banner-2020.png" alt="" /></a>'}
        </AhrefField>
      </BanerContainer>
    </>
  )
}