import React from 'react';
import {
  NewsCompetitionsTitle,
  ResultHomeBox, ResultHomeButton,
  ResultHomeContainer,
  ResultHomeContent,
  ResultHomeText
} from "../../Style/HomePage/News";
import {NavLink} from "react-router-dom";
import {Button} from "../../components/UI/Button";

export const ResultHome = () => {
  return (
    <ResultHomeContainer>
      <ResultHomeContent>
        <ResultHomeBox>
          <NewsCompetitionsTitle>Результаты Конкурса</NewsCompetitionsTitle>
          <ResultHomeText>
            <p>&#8195;&#8195;Министерство просвещения Российской Федерации утвердило итоги
              Всероссийского дистанционного конкурса среди классных руководителей на
              лучшие методические разработки воспитательных мероприятий.</p>
            <p>&#8195;&#8195;Список, включающий победителя, призеров и лауреатов конкурса, в
              срок до 20 декабря 2020 года будет размещен на портале «Российское
              образование». Победитель, призеры и лауреаты конкурса будут награждены
              соответствующими дипломами, а также памятными подарками от
              организации-партнера конкурса – Российского движения школьников.</p>
            <p>&#8195;&#8195;
              Методические разработки победителя, призеров и лауреатов конкурса опубликованы
              в официальном издании Минпросвещения России - электронном периодическом журнале
              "Вестник образования". Ознакомиться с ними можно по ссылке
              <a href="https://vestnik.edu.ru/methodic"> https://vestnik.edu.ru/methodic</a>
            </p>
            <p>&#8195;&#8195;
              Общий рейтинговый список участников конкурса, согласно
              Положению о конкурсе, не публикуется. Информация о порядке получения
              электронных сертификатов участников будет размещена на сайте конкурса
              дополнительно.
            </p>
            <p>&#8195;&#8195;
              От лица Оператора конкурса (ФГАОУ ДПО «Академия
              Минпросвещения России») поздравляем победителя, призеров и лауреатов
              конкурса!
            </p>
          </ResultHomeText>
          <ResultHomeButton>
          <NavLink to={'/results'}>
            <Button value={'Таблица с результатами конкурса'} height={'550px'} type={'primary'}/>
          </NavLink>
          </ResultHomeButton>
        </ResultHomeBox>
      </ResultHomeContent>
    </ResultHomeContainer>
  )
}