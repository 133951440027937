import React, {useEffect} from "react";
import {NewsPageContainer, NewsPageContent, NewsPageRow, NewsPageRowLeft} from "../Style/NewsPage/NewsPage";
import {BreadcrumbsItem, BreadcrumbsItemLink, DocumentsPageContent} from "../Style/DocumentsPage/DocumentsPage";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {DisplayFlex, PageTitle} from "../Style/Pages";
import {NewsCalendar} from "../components/News/NewsCalendar";
import {NewsPageItem} from "../components/News/NewsPageItem";
import {news} from "../Docs/NewsDoc";

export  const NewsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <NewsPageContainer>
      <DocumentsPageContent>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs separator={' / '}>
                <Breadcrumb data={{
                  title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                  pathname: '/'
                }}/>
                <Breadcrumb data={{
                  title: <BreadcrumbsItemLink>Новости</BreadcrumbsItemLink>,
                  pathname: '/news'
                }}/>
              </Breadcrumbs>
            </Col>
            <Row>
              <Col lg={12}>
                <DisplayFlex>
                  <PageTitle>Новости</PageTitle>
                </DisplayFlex>
              </Col>
            </Row>
          </Row>

          <NewsPageContent>
            <NewsPageRowLeft>
              {news.map(node => {
                return (
                  <NewsPageItem key={node.id} date={node.date} data={node.data} img={node.img}/>
                )
              })}
            </NewsPageRowLeft>
            <NewsPageRow>
              <NewsCalendar/>
            </NewsPageRow>
          </NewsPageContent>

        </Grid>
      </DocumentsPageContent>
    </NewsPageContainer>
  )
}