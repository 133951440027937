import React from "react";
import {Logo} from "../../components/UI/Logo";
import {HeaderMenu} from "../../components/Header/HeaderMenu";
import {HeaderLogin} from "../../components/Header/HeaderLogin";
import {HeaderBackground, HeaderInfo, HeaderTitleText, MenuContainer, PageContent} from "../../Style/Header";
import {HeaderRoleButtons} from "../../components/Header/HeaderRoleButtons";

export const Header = () => {

  return (
    <HeaderBackground>
      <PageContent>
        <MenuContainer>
          <Logo/>
          <HeaderMenu/>
          <HeaderLogin/>
        </MenuContainer>

        <HeaderInfo>
          <div>
          <HeaderTitleText>ВСЕРОССИЙСКИЙ КОНКУРС</HeaderTitleText><HeaderTitleText> СРЕДИ КЛАССНЫХ РУКОВОДИТЕЛЕЙ НА ЛУЧШИЕ МЕТОДИЧЕСКИЕ РАЗРАБОТКИ ВОСПИТАТЕЛЬНЫХ МЕРОПРИЯТИЙ</HeaderTitleText>
          </div>
          <HeaderRoleButtons/>
        </HeaderInfo>
      </PageContent>
    </HeaderBackground>
  )
}