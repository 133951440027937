import React, {useEffect} from "react";
import {ExpertContainer} from "../Style/MemberPage";
import {
  BreadcrumbsItem,
  BreadcrumbsItemLink, DocsContent,
  DocsTypesTitle, DocsTypesTitleBg,
  DocumentsPageContent
} from "../Style/DocumentsPage/DocumentsPage";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Breadcrumb, Breadcrumbs} from "react-breadcrumbs";
import {PageTitle} from "../Style/Pages";
import {SetDocs} from "../components/Documents/SetDocs";
import expertMain from "../assets/file/ExpertMain.docx";
// import expertAdd from "../assets/file/ExpertAdd.docx";

export const ExpertPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return(
    <ExpertContainer>
      <DocumentsPageContent>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Breadcrumbs separator={'  /  '}>
                <Breadcrumb data={{
                  title: <BreadcrumbsItem>Главная страница</BreadcrumbsItem>,
                  pathname: '/'
                }}/>
                <Breadcrumb data={{
                  title: <BreadcrumbsItemLink>Экспертам</BreadcrumbsItemLink>,
                  pathname: '/docs'
                }}/>
              </Breadcrumbs>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <PageTitle>Экспертам</PageTitle>
            </Col>
          </Row>

          <Col lg={12}>
            <DocsTypesTitle>
              <DocsTypesTitleBg/>
              <h2>ДОКУМЕНТЫ</h2>
            </DocsTypesTitle>
          </Col>
          <Col lg={10} md={12}>
            <DocsContent>
              <SetDocs
                file={expertMain}
                type={'DOC'}
                size={'16 Кб'}
                title={'Порядок и критерии оценивания конкурсных материалов'}
              />
              {/*<SetDocs*/}
              {/*  file={expertAdd}*/}
              {/*  type={'DOC'}*/}
              {/*  size={'15 Кб'}*/}
              {/*  title={'Форма предоставления информации о кандидатуре в состав жюри Всероссийского дистанционного конкурса среди классных руководителей на лучшие методические разработки воспитательных мероприятий от субъекта Российской Федерации'}*/}
              {/*/>*/}
            </DocsContent>
          </Col>
        </Grid>
      </DocumentsPageContent>
    </ExpertContainer>
  )
}