import styled from 'styled-components'
import {
  HeaderMenuBox,
  RoleButtonArea,
  RoleButtonBox,
  RoleButtonDescription,
  RoleButtonImage,
  RoleButtonsBox
} from "./Header";
import logoHeader from '../assets/svg/LayoutHeaderLogo.png'
import backGround from '../assets/img/header/MainHeaderBg.png'
import bgRole from '../assets/img/header/RoleBg.png'
import smBg from '../assets/img/header/HeaderBg800.png'

export const LayoutHeaderContainer = styled.div`
  width: 100%;
  padding-bottom: 30px;
  background-size: cover;
  background-image: url(${backGround});
  
  @media (max-width: 800px)  {
    background-image: url(${smBg});
  } 
`

export const LayoutHeaderContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  
  & ${RoleButtonArea} {
    width: 111px;
    height: 80px;
    background-image: url(${bgRole});
  }
  
  & ${RoleButtonImage} {
    width: 65px;
    height: 65px;
  }
  
  & ${RoleButtonDescription} {
    margin-left: 15px;
    text-align: left;
    max-width: 118px;
    color: #ffffff;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.35px;
    text-transform: uppercase;
  }
  
  & ${RoleButtonBox} {
    flex-direction: row;
  }
  
  & ${RoleButtonsBox} {
    margin: 0 100px;
  }
`

export const LayoutMenu = styled.div`
  display: flex;
  margin-left: 71px;
  justify-content: space-between;
  padding-top: 31px;
  align-items: center;
  margin-bottom: 67px;
  
  & ${HeaderMenuBox} {
    margin-left: 0;
  }
  
  @media (max-width: 800px)  {
    margin-bottom: 0;
  } 
`
export const LayoutMenuLogo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 100px;
  
  & p {
    max-width: 300px;
    color: #053957;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    
    @media (max-width: 1080px) {
      display: none;
    }
  }
`
export const LayoutLogoImage = styled.div`
  min-width: 93px;
  margin-right: 10px;
  height: 78px;
  background-image: url(${logoHeader});
  background-size: cover;
`