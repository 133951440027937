import styled from 'styled-components'

export const PageTitle = styled.h1`
  margin-top: 36px;
  margin-bottom: 61px;
  color: #052b46;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
`

export const PageDescription = styled.div`
  font-size: 22px;
  letter-spacing: 1px;
  line-height: 24px;
  color: rgb(104, 115, 181);
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: underline;
  margin-top: 36px;
  margin-left: 20px;
  cursor: pointer;
`

export const DisplayFlex = styled.div`
  display: flex;
`
export const DisplayFlexCol = styled.div`
  flex-direction: column;
  display: flex;
`

export const DisplayFlexCenter = styled.div`
  display: flex;
  align-items: center;
`

export const DivMinWidth200 = styled.div`
  min-width: 200px;
`
