import React from "react";
import {
  DropDownText,
  HeaderDropDown,
  HeaderDropDownBox,
  HeaderMenuBox,
  HeaderMenuLink,
  HeaderMenuList
} from "../../Style/Header";
import {Link, NavLink} from "react-router-dom";

export const HeaderMenu = () => {

  return(
    <HeaderMenuBox>
      <HeaderMenuList>
        <HeaderDropDownBox>
          <HeaderMenuLink>
            Конкурс
          </HeaderMenuLink>
          <HeaderDropDown>
            <NavLink to={'/about'}>
              <DropDownText>
                О конкурсе
              </DropDownText>
            </NavLink>
            <NavLink to={'/topic'}>
              <DropDownText>
                Тематические направления
              </DropDownText>
            </NavLink>
            <NavLink to={'/calendar'}>
            <DropDownText>Календарь</DropDownText>
            </NavLink>
          </HeaderDropDown>
        </HeaderDropDownBox>

        <Link to={'/docs'}>
          <HeaderMenuLink>
            Документы
          </HeaderMenuLink>
        </Link>
        <NavLink to={'/news'}>
          <HeaderMenuLink>
            Новости
          </HeaderMenuLink>
        </NavLink>
      </HeaderMenuList>
    </HeaderMenuBox>
  )
}